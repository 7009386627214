// session缓存

var userEmail = null;
var isVip = null;

export default{
	initUserEmail(e){
		userEmail = e;
	},
	initUserVip(v){
		isVip = v;
	},
	isUserVip(){
		return isVip == true;
	},
	setFromAliyun(value){
		if(value == true){
			sessionStorage.setItem('FromAliyun', true);
		}else {
			sessionStorage.setItem('FromAliyun', false);
		}
	},
	isFromAliyun(){
		return sessionStorage.getItem('FromAliyun');
	},
	bell(key){
		key = userEmail + key;
		let result = getLocal(key);
		if(result == null){
			setLocal(key, true);
		}
		return result == null;
	},
	// 清除Session数据
	clear(){
		sessionStorage.clear();
	},
	// jwt
	saveAuthorization(token){
		sessionStorage.setItem('Authorization', token);
	},
	getAuthorization(){
		return sessionStorage.getItem('Authorization');
	},
	// userinfo
	getUserInfo(){
		return getSession("userinfo")
	},
	setUserInfo(value){
		setSession("userinfo", value)
	},
	// gifts
	getGifts(){
		return getSession("gifts")
	},
	setGifts(value){
		setSession("gifts", value)
	},
	// order
	getOrder(id){
		return getSession("order_" + id)
	},
	saveOrder(id, value){
		setSession("order_" + id, value)
	},
	// drives
	setCloudPath(value){
		setSession("cloudPath", value)
	},
	getCloudPath(){
		return getSession("cloudPath")
	},
	setDriveAccount(authId, value){
		setSession(authId + "_driveAccount", value)
	},
	getDriveAccount(authId){
		return getSession(authId + "_driveAccount")
	},
	getNewDriveRefresh(){
		return getSession("newDriveRefresh")
	},
	setNewDriveRefresh(value){
		setSession("newDriveRefresh", value)
	},
	getDrives(){
		return getSession("drives")
	},
	setDrives(value){
		setSession("drives", value)
	},
	getDriveFiles(authId, folderId){
		return getSession("drive_file_" + authId + folderId);
	},
	setDriveFiles(authId, folderId, value){
		setSession("drive_file_" + authId + folderId, value);
	},
	// transfers
	getTransferPath(){
		return getSession("transfer_path")
	},
	setTransferPath(value){
		setSession("transfer_path", value)
	},
	getTransferList(){
		return getSession("transfer_list")
	},
	setTransferList(value){
		setSession("transfer_list", value)
	},
	getTransferTask(id){
		return getSession("transfer_task_" + id)
	},
	setTransferTask(id, value){
		setSession("transfer_task_" + id, value)
	},
	getTransferTaskProgress(sessionId){
		return getSession("transfer_task_progress" + sessionId)
	},
	setTransferTaskProgress(sessionId, value){
		setSession("transfer_task_progress" + sessionId, value)
	},
}

function buildKey(key){
	if(userEmail){
		return userEmail + "_" + key;
	}
	return sessionStorage.getItem('Authorization') + "_" + key;
}

function getSession(key){
	return JSON.parse(sessionStorage.getItem(buildKey(key)));
}

function setSession(key, value){
	return sessionStorage.setItem(buildKey(key), JSON.stringify(value))
}

function getLocal(key){
	if(userEmail){
		key = userEmail + "_" + key;
	}
	return JSON.parse(localStorage.getItem(key));
}

function setLocal(key, value){
	if(userEmail){
		key = userEmail + "_" + key;
	}
	return localStorage.setItem(key, JSON.stringify(value))
}

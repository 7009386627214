import VueRouter from 'vue-router'
import Vue from 'vue'
import Store from '../store/index.js'

Vue.use(VueRouter)

// 静态路由表
const routes = [{
		path: "/",
		redirect: "/home/drive"
	},
	{
		path: "/reset",
		component: () => import("../components/ResetPassword.vue"),
	},
	{
		path: '/driveSuccess',
		component: () => import("../components/SuccessCloud.vue")
	},
	{
		path: "/home",
		component: () => import("../components/MineHome.vue"),
		children: [{
				path: 'transfer',
				component: () => import("../components/transfer/BuildTransfer.vue")
			},
			{
				path: 'backup',
				component: () => import("../components/MineBackup.vue"),
			},
			{
				path: 'list',
				component: () => import("../components/TaskList.vue"),
			},
			{
				path: 'gift',
				component: () => import("../components/MineGift.vue"),
			},
		{
			path: 'gift/:giftId/:giftNumber/:giftSeq',
				component: () => import("../components/MineOrder.vue"),
		},
			{
				path: 'drive',
				component: () => import("../components/clouds/AddCloud.vue")
			},
			{
				path: 'item/:id',
				component: () => import("../components/clouds/CloudExplorer.vue"),
				meta: {
					keepAlive: true
				}
			}
		]
	},
]

const router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach((to, from, next) => {
	let path = to.path;
if (path && path.indexOf("bearar") != -1) {
	let token = path.substring(path.lastIndexOf("/") + 1);
	Store.saveAuthorization(token);
	Store.setFromAliyun(true);
	next("/");
}
else if (path && path.indexOf("bearer") != -1) {
		let token = path.substring(path.lastIndexOf("/") + 1);
		Store.saveAuthorization(token);
		next("/");
	} else {
		next();
	}
})


// 防止动态路由跳转2次
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

export default router;
export default {

	// bytes - 必需的。提供以字节格式的大小。
	// decimalpoint - 可选。指定小数点。
	formatFileSize(bytes, decimalPoint) {  
		if (bytes == 0) {
			return '0 B';
		}
		if (bytes == null || bytes == "") {
			return "";
		}
	    let k = 1024;  
	    let dm = decimalPoint || 2;  
	    let sizes = ['B', 'KB', 'MB', 'GB'];  // 只保留到GB  
	    let i = Math.min(Math.floor(Math.log(bytes) / Math.log(k)), sizes.length - 1);  // 取最小值以确保不超过GB  
	    if (decimalPoint == 0) {  
	         return Math.ceil((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];  
	    }  
	    return (bytes / Math.pow(k, i)).toFixed(dm) + ' ' + sizes[i];  
	},
	
	computerTraffic(used, total){
		if(total == -1){
			let param = Math.min(1, used / (10 * 1024 * 1024 * 1024))
			let r = Math.floor(param * 100)
			return Math.min(20, r)
		}else{
			return Math.floor((used / total) * 100)
		}
	},

	// s 秒数
	// return 00:00:00
	secondsToTime(s) {
		var t = "";
		if (s > -1) {
			var day = Math.floor(s / 86400);
			var hour = Math.floor(s / 3600) % 24;
			var min = Math.floor(s / 60) % 60;
			var sec = s % 60;
			
			if(day > 0){
				if(day < 10){
					t = '0' + day + "天";
				}else{
					t = day + "天";
				}
			}
			
			if(hour > 0){
				if (hour < 10) {
					t += ('0' + hour + "时");
				} else {
					t += (hour + "时");
				}
			}else if(day > 0){
				t += ('0' + hour + "时");
			}
			
			if (min < 10) {
				t += "0";
			}
			t += min + "分";
			// 秒
			if(t.indexOf('天') == -1){
				if (sec < 10) {
					t += "0";
				}
				t += (sec + "秒");
			}
		}
		return t;
	},


	getUuid() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = (Math.random() * 16) | 0,
				v = c == 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	},
	//加密
	base64Encode(str) {
		return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
			function toSolidBytes(match, p1) {
				return String.fromCharCode('0x' + p1);
			}));
	},
	//解密
	base64Decode(str) {
		// Going backwards: from bytestream, to percent-encoding, to original string.
		return decodeURIComponent(atob(str).split('').map(function (c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));
	}
	
}





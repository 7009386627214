import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import router from './router/index.js'
// 引入网络请求
import http from './js/HttpRequest.js'
// 引入公共工具
import tool from './js/CommonTool.js'
// 引入GA工具
import ga from './js/GoogleAnalysize.js'

Vue.config.productionTip = false
// 配置全局http请求
Vue.prototype.$http=http
// 配置全局公共工具
Vue.prototype.$tool=tool
// 配置全局GA
Vue.prototype.$GA=ga

new Vue({
  vuetify,
  router,
  viewport: {  
      width: 'device-width',  
      initialScale: 1  
    }, 
  render: h => h(App)
}).$mount('#app');

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

import en from './en.js'

import cn from './zh-cn.js'

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1d8fb8',
        secondary: '#FBC02D',
		third:'#187596',
		four:"#53bee4",
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
  lang: {
      locales: { cn, en},
      current: 'cn',
   },
});

export default{
	//事件统计
	event(c, a, l) {
	  let eventCategory = c//事件类别
	  let eventAction = a//事件操作
	  let eventLabel = l//事件标签
	  window.gtag('event', eventAction, {
	    event_category: eventCategory,
	    event_label: eventLabel,
	    value: 1,
	    send_to: 'UA-XXXXX-X'//跟踪ID
	  })
	}
}
	

export default {
  web: {
  	index:{
  		login:"Sign In",
  		sigupForFree:"Sign up Free"
  	 },
    signin:{
  		email:"Email Address",
  		password:"Password" 
  	},
  	signup:{
  		email: "Enter your email",
  		password:"Enter your Password",
  		repassword:"Confirm the password"
  	},
  	forgetpassword:{
  		email:"Enter your email",
  		code:"Captcha"
  	},
	reset:{
		oldPassword:"Enter your old Password",
		newPassword:"Enter your new Password",
	}
  },
  common:{
	   close:"Close",
	   save:"Save",
	   back:"Back",
	   inDevelopment:"In development",
	   items:"items",
	   unopened:"unopened"
  },
  home:{
  	  myDrives:"My Drives",
	  transfer:"Transfer",
	  myTasks:"My Tasks",
	  traffic:"Traffic"
  },
  cloud:{
  	  new:"New",
	  emptuyList:"This is an empty list.",
	  emptuyCloudList:"This is an empty list.",
	  newFolder:"New Folder",
	  emptyFolder:"This is an empty folder.",
	  refresh:"Refresh",
	  delete:"Delete",
	  deleting:"Deleting",
	  deleteTip:"There are tasks running in this cloud drive, deleting the cloud drive will cause the task to be lost.",
	  deleteSure:"Yes",
	  addSuccessTip:"You have successfully added",
	  addExistTip:"This cloud drive already exists",
	  addFailTip:"You have declined or failed to add",
	  addSuccess:"The cloud drive is successfully added,Please go back to the main page and refresh.",
	  addExplain:"You can select a supported cloud drives in the list of supported cloud drives below for authorized login, click the third-party authorization login page that will pop up, if there is no response, please check whether your browser has blocked the pop-up window. ",
	  baidu:"BaiduNetdisk",
	  aliyun:"AliyunDrive",
	  onedrive:"Onedrive",
	  googleDrive:"Google Drive",
	  dropbox:"Dropbox",
	  future:"In the future, we will develop and support more third-party cloud drives, if you want to implement cloud drives, you can tell us by email.",
	  explorer:{
		  myFiles:"My Files",
		  name:"name",
		  size:"size",
		  updateTime:"update time",
		  refresh:"Refresh",
		  delete:"Delete",
		  preview:"Preview",
		  rename:"Rename",
		  download:"Download",
		  copyTo:"Copy to",
		  moveTo:"Move to",
		  site:"Site"
	  }
  },
  task:{
	  new:"Task",
  	  title:"Task  List",
	  newTask:"New Task",
	  deleteEmpty:"Delete Empty",
	  empty:"This is an empty task list",
	  restart:"Restart",
	  completed:"Completed",
	  status:{
		  running:"Running",
		  waiting:"Waiting",
		  success:"Success",
		  failure:"Failure",
		  partialSuccess:"PartialSuccess",
		  unkown:"Unkown"
	  },
	  itemsSelected:"Item(s) selected",
	  sourceNotSelect:"The transport source has not been selected",
	  destinationNotSelect:"The transfer destination has not been selected"
  },
  transfer:{
	  addNewCloud:"Add new cloud",
  	  source:"Source",
	  destination:"Destination",
	  conflictTile:"File conflict handling policy",
	  taskName:"Task name",
	  cloudTips:"来源和目标单击名称可以进入云盘或文件夹",
	  sendEmail:"Send email after completion",
	  createNow:"Create Now",
	  inLine:"In line",
	  calculating:"Calculating",
	  timeRemaining:"Time remaining",
	  folder:"Folder",
	  file:"File",
	  size:"Size",
	  skipped:"Skipped",
	  failed:"Failed",
	  stop:"Stop",
	  loading:"Loading",
	  From:"From",
	  to:"To",
	  stoppingTask:"Stopping task",
	  taskSetting:"Task Setting",
	  fileConfict:"File confict",
	  notificationEmail:"Notification email",
	  notificationEmailTip:"Enter your notification email",
	  time:"time",
	  avgRate:"Avg Rate",
	  startTime:"Start time",
	  endTime:"End time",
	  failDetails:"fail Details",
	  FailDetails:"Fail Details",
	  path:"path",
	  cause:"cause",
	  conflict:{
		  skip:"Skip the same file",
		  rename:"Rename the new file",
		  replace:"Overwrite the old file",
	  }
  },
  user:{
  	  changeAvatar:"Change Avatar",
	  avatarPreview: "Preview",
	  avatarUploadClick:"Click here to select a file",
	  avatarUpload:"Upload",
	  changePassword:"Change Password",
	  resetPassword:"Reset your password",
	  newPassword:"Enter your new Password",
	  newPasswordCheck:"The new Password in 8 - 20 characters",
	  oldPassword:"Enter your old Password",
	  oldPasswordCheck:"The old Password in 8 - 20 characters",
	  passwordLimit:"At least 8 characters",
	  logout:"Logout",
	  start:"Start",
	  feedback:"Feedback",
	  passwordCheck:"8 to 20 characters",
	  unlimited:"Unlimited",
	  sessionExpired:"The login session has expired, please log in again.",
	  feedbackDescribe:"Dear user, thank you very much for using our products, if there are some problems during use, you can feedback it to the following mailbox, we will deal with and reply to you within 24 hours.In the email, you can bring your account number, problem details, with operation steps or pictures, videos, etc., which is conducive to locating your problem faster, thank you!"
  },
  gift:{
  	  pricingTable:"Pricing Table",
	  describe:"Thank you very much for your support and use. Before the official launch of the product, all functions are free, and the beta plan will be automatically given when registering, valid until the official launch of the product.",
	  currentPlan:"Current plan",
	  a:{
		  title:{
			  price:"$0.00",
			  name:"Beta Version"
		  },
		  content:{
			  a:"Unlimited data traffic",
			  b:"General speed",
			  c:"Transfer between cloud storage",
			  d:"All operations of cloud manager",
			  e:"24/7/365 Support",
		  }
	  }
  }
}